import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Network/MQTT/SuccessBox';
import PrimaryBox from 'components/Web_User_Interface/1080p_Series/Network/MQTT/PrimaryBox';
import DangerBox from 'components/Web_User_Interface/1080p_Series/Network/MQTT/DangerBox';
import MQTTTable from 'components/Web_User_Interface/1080p_Series/Network/MQTT/mqttTable';
import TimeLine from 'components/Products/CommentList/MqttUpdatesList';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Network Menu // INSTAR MQTT Broker",
  "path": "/Web_User_Interface/1080p_Series/Network/MQTT/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Configure the INSTAR MQTT broker - activate the server, or switch to an external broker. You can also configure the authentication and add your personal SSL certificate.",
  "image": "./WebUI_1080p_SearchThumb_Smarthome_MQTT.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Smarthome_MQTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <SEOHelmet title='Smarthome Menu // INSTAR MQTT Broker' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Configure the INSTAR MQTT broker - activate the server, or switch to an external broker. You can also configure the authentication and add your personal SSL certificate.' image='/images/Search/WebUI_1080p_SearchThumb_Smarthome_MQTT.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Smarthome_MQTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Netzwerk/MQTT/' locationFR='/fr/Web_User_Interface/1080p_Series/Network/MQTT/' crumbLabel="MQTT Broker" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "smarthome-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#smarthome-menu",
        "aria-label": "smarthome menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smarthome Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/951ac639d867bce674bff50807208641/8dd93/1080p_Settings_Features_MQTT.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACxklEQVQ4y23S22vbZhjHcd+3YVs7lwU7liU78SmMemFOG/kgJz5EsazzKbVUN20pXZqUwSBrx7qLdIyym7ExGGuvBtv+ze+QTOp07cWPR69e+PA87/tmNjc3aTQaaer1OtVqlXKphBK/QP3mL5TjP9k9eUP/cp68Ye/kNc53f9Psuwj5VdY3KozGYzKNev0tVqvV0mSznzLyH3P07Df805+wv3qJ++THNM7xIu7xOfHzP/iiPeajlStcv3aNUlEgU78EJt0l4MrKVbryLeazgEPPJHAMHOMAa6rimpP0O6lxHLPV/Jz1cpFKOU/uxtUlmEALsJ6CPUXh/oOHzKKI8PAOtuNi2Q66YbKvHqCqB2hTHVNTOIoGDHZbCPnsu2CtVk3R3GqWycE+URQThgGWZWHbNpqm0e12kWUZWd6h21M4tMdE2k1G3SbaWH6/w2RdkdZQxyOiOMb3/XfA4XBIp9Oh3W6j9PfwjCH2XoPpXpOjw97/O1ygjco6tqExv3ePIFh2aJkmhqEznU6Z6jpTb45v7TNqCfiTJj/Eu0vwAq1Uq7S2bhL4PnF8F9d1MU0Tx7GZTCb0lD69ZOztJr2OzKC3zXbtBs8fjXn1cEIm7ejSGW5U68iNHLaqEN6J0g6T2LaDY1uEroEXzvC//gXv5BVDZZt2M8vvLyzOXHkBJo97MW6CbpArqeyO5sxmPkEQ4roOmmFxelfn32+HnD8N0b//B+PsNaZ6G30g8TRqo98qL8Dl+dWo19bZcc7R/FN818B1PTzPw3Usgvlj5s9+5cGjM342TF7aHuN+i/aXJcqlNT75+MoCvMgFWioJtFpb6RNJbjO51U5bptMf0ZmE9Aca3u0dnHYnna5YFJDEYlozS2hZRVGiUCi8n7U8hdxnrOVzrAoFVgsFBEGgWEwwEVEUPwxKkpRufjDpnoQkimkW/4tpEvg/5yviN2eefngAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/951ac639d867bce674bff50807208641/e4706/1080p_Settings_Features_MQTT.avif 230w", "/en/static/951ac639d867bce674bff50807208641/d1af7/1080p_Settings_Features_MQTT.avif 460w", "/en/static/951ac639d867bce674bff50807208641/7f308/1080p_Settings_Features_MQTT.avif 920w", "/en/static/951ac639d867bce674bff50807208641/19805/1080p_Settings_Features_MQTT.avif 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/951ac639d867bce674bff50807208641/a0b58/1080p_Settings_Features_MQTT.webp 230w", "/en/static/951ac639d867bce674bff50807208641/bc10c/1080p_Settings_Features_MQTT.webp 460w", "/en/static/951ac639d867bce674bff50807208641/966d8/1080p_Settings_Features_MQTT.webp 920w", "/en/static/951ac639d867bce674bff50807208641/61eb3/1080p_Settings_Features_MQTT.webp 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/951ac639d867bce674bff50807208641/81c8e/1080p_Settings_Features_MQTT.png 230w", "/en/static/951ac639d867bce674bff50807208641/08a84/1080p_Settings_Features_MQTT.png 460w", "/en/static/951ac639d867bce674bff50807208641/c0255/1080p_Settings_Features_MQTT.png 920w", "/en/static/951ac639d867bce674bff50807208641/8dd93/1080p_Settings_Features_MQTT.png 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/951ac639d867bce674bff50807208641/c0255/1080p_Settings_Features_MQTT.png",
              "alt": "Web User Interface - 1080p Series - Network MQTT",
              "title": "Web User Interface - 1080p Series - Network MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <MQTTTable mdxType="MQTTTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox mdxType="DangerBox" />
    <h3 {...{
      "id": "how-to-talk-mqtt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-to-talk-mqtt",
        "aria-label": "how to talk mqtt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to talk MQTT`}</h3>
    <p>{`When you are interested in using the MQTT interface to add your camera to a smarthome system, you are probably already familiar with our `}<a parentName="p" {...{
        "href": "/en/1080p_Series_CGI_List/"
      }}>{`HTTP/S CGI interface`}</a>{` that you can use to send commands to your camera through your web browser.`}</p>
    <p>{`MQTT works much the same way. Every CGI command corresponds to an `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`MQTT topic`}</a>{` and every topic can carry specific payloads. For example to activate the first Motion Detection Area on your camera, you can send the following CGI command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmdattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`\${val}`}</span></code></pre></div>
    <p>{`Where the value (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`val`}</code>{`) can be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` - depending on whether you want to deactivate or activate the detection area.`}</p>
    <p>{`In MQTT doing the same thing looks like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/area1/enable	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`The topic to switch the area on or off is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/area1/enable`}</code>{` and you have to send either a value of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{` to switch it.`}</p>
    <p>{`You can find an overview over `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`all available MQTT topics and their Payload here`}</a>{`.`}</p>
    <h3 {...{
      "id": "syntax",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#syntax",
        "aria-label": "syntax permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Syntax`}</h3>
    <p>{`In order to send an MQTT command to the camera, an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/`}</code>{` must first be prepended. To address exactly the right camera you now have 3 options. Either you use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/local/`}</code>{` to address the camera on which the MQTT Broker is running. Or you can name a specific camera by its `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/System/Info/"
      }}>{`(LAN!) MAC address`}</a>{`. For example, if the LAN MAC of your camera is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`10:D1:DC:21:8F:96`}</code>{`, then the MQTT ID of this camera is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`10D1DC218F96`}</code>{` and the MQTT Topic must contain `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/10D1DC218F96/`}</code>{` to address the camera. And last but not least you have the possibility to address all cameras in the MQTT network at once via `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/all/`}</code>{`:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/local/alarm/area1/enable`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/10D1DC218F96/alarm/area1/enable`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/all/alarm/area1/enable`}</code></li>
    </ul>
    <p>{`After you have updated such a command topic, your camera will return a status topic to tell you that everything worked:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/local/alarm/area1/enable`}</code>{` `}<strong parentName="li">{`Command Topic`}</strong></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/local/status/alarm/area1/enable`}</code>{` `}<strong parentName="li">{`Status Topic`}</strong></li>
    </ul>
    <p>{`More information can be found in our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`MQTT Topics and Payloads Overview`}</a>{`.`}</p>
    <h3 {...{
      "id": "what-software-can-i-use-this-with",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-software-can-i-use-this-with",
        "aria-label": "what software can i use this with permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What Software can I use this with?`}</h3>
    <p>{`We are collecting an ever growing `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/#software"
      }}>{`list of software`}</a>{` that is compatible with the MQTT protocol. An easy way to get started are Dashboard and Testing applications that can be installed on your Android phone or under Windows or macOS:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Android_Apps/"
        }}>{`Android Apps for MQTT`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
        }}>{`Windows and macOS Apps for MQTT`}</a></li>
    </ol>
    <p>{`Both Android apps are simple dashboards that allow you to control your camera through the MQTT interface. In case of the desktop apps, they are used to test your connection to your camera, record interactions between your camera and client application and to debug your MQTT network.`}</p>
    <p>{`You can also check the MQTT logon your camera to debug your setup. All error messages will be listed here:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://Camera-IP/tmpfs/mqtt-log`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "802px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4515a6652933dda8c6e3a3fc5cc80293/5a6dd/INSTAR_MQTT_Log_File.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "22.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAvUlEQVQY032LWw/BMBiG94smW4I4zZiQ/XwuiEPHrNhJa9G0X6tGxh3iyXvx5kkeYzj2vYnvuJ7jes1Wt9cfuINRu+PUG61Gs23ZddO0zJr9c8ZytZ7NpvP5AqFgF4anU0wvlBJCCSWE5H8x4jTFB5wkSZzEb8UYU0pKAK314y/GbrvdbFYYRwFCaI0Ox2OW51mWXhkTQnDOOYDgAgSU9/IrDvdVGQR4H53PpCgKrW/yhZIKAKoHUilVlp/xE0N07xtVEiatAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4515a6652933dda8c6e3a3fc5cc80293/e4706/INSTAR_MQTT_Log_File.avif 230w", "/en/static/4515a6652933dda8c6e3a3fc5cc80293/d1af7/INSTAR_MQTT_Log_File.avif 460w", "/en/static/4515a6652933dda8c6e3a3fc5cc80293/903ec/INSTAR_MQTT_Log_File.avif 802w"],
              "sizes": "(max-width: 802px) 100vw, 802px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4515a6652933dda8c6e3a3fc5cc80293/a0b58/INSTAR_MQTT_Log_File.webp 230w", "/en/static/4515a6652933dda8c6e3a3fc5cc80293/bc10c/INSTAR_MQTT_Log_File.webp 460w", "/en/static/4515a6652933dda8c6e3a3fc5cc80293/85811/INSTAR_MQTT_Log_File.webp 802w"],
              "sizes": "(max-width: 802px) 100vw, 802px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4515a6652933dda8c6e3a3fc5cc80293/81c8e/INSTAR_MQTT_Log_File.png 230w", "/en/static/4515a6652933dda8c6e3a3fc5cc80293/08a84/INSTAR_MQTT_Log_File.png 460w", "/en/static/4515a6652933dda8c6e3a3fc5cc80293/5a6dd/INSTAR_MQTT_Log_File.png 802w"],
              "sizes": "(max-width: 802px) 100vw, 802px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4515a6652933dda8c6e3a3fc5cc80293/5a6dd/INSTAR_MQTT_Log_File.png",
              "alt": "Web User Interface - 1080p Series - Network MQTT",
              "title": "Web User Interface - 1080p Series - Network MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`For compatible `}<strong parentName="p">{`Smarthome Systems`}</strong>{` please check out our guides for :`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/"
        }}>{`Home Assistant`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/OpenHAB2/"
        }}>{`OpenHAB 2`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Athom_Homey_MQTT_Client/"
        }}>{`Athom Homey`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Android_Apps/"
        }}>{`Android Apps`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
        }}>{`Windows & macOS Apps`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Node-RED/"
        }}>{`Node-RED`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Homematic_CCU3_and_RedMatic/"
        }}>{`Homematic`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Loxone/"
        }}>{`Loxone`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/homee/"
        }}>{`homee`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/ioBroker/"
        }}>{`IOBroker`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/FHEM_House_Automation/"
        }}>{`FHEM`}</a></li>
    </ol>
    <p>{`And more to follow...`}</p>
    <p>{`Please read our FAQ if you want to use a `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/INSTAR_MQTT_Self_Signed_SSL_Certificate/"
      }}>{`self-signed SSL certificate for the INSTAR MQTT-Broker`}</a>{`.`}</p>
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      